import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import {
  Agenda,
  Audio,
  Book,
  Contact,
  ID,
  Microphone,
  Video,
  Media,
  Shop,
} from "../../svg";

const iconMap = {
  agenda: Agenda,
  audio: Audio,
  book: Book,
  contact: Contact,
  id: ID,
  microphone: Microphone,
  video: Video,
  media: Media,
  shop: Shop,
};

export const H1 = styled.h1`
  font-family: Randy;
  font-size: 42px;
  margin: 0;
  line-height: 1.2;
  margin-bottom: 64px;
  text-transform: uppercase;
  color: var(--headers);
  font-weight: 400;

  ${media.lessThan("small")`
    font-size: 32px;
  `}
`;

export const H2 = styled.h2`
  font-family: Randy;
  font-size: ${(p) => (p.small ? "28px" : "36px")};
  margin: 0;
  line-height: 1.2;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: var(--headers);
  font-weight: 400;

  ${media.lessThan("small")`
    font-size: ${(p) => (p.small ? "24px" : "28px")} ;
  `}
`;

export const H3 = styled.h3`
  font-family: Randy;
  font-size: 24px;
  margin: 0;
  line-height: 1.2;
  margin-bottom: 12px;
  text-transform: uppercase;
  color: var(--headers);
  font-weight: 400;

  ${media.lessThan("small")`
    font-size: 20px;
  `}
`;

const PageHeaderBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.center ? "center" : "flex-start")};
  margin: 0 auto;
  margin-bottom: ${(p) => (p.noMargin ? 0 : 48)}px;
  padding: 0px 16px;
  max-width: 960px;
`;

export function PageHeader({ title, center, icon, style }) {
  const Icon = iconMap[icon];

  return (
    <PageHeaderBase
      center={center}
      style={{
        ...style,
      }}
    >
      <H1 style={{ marginBottom: 0 }}>{title}</H1>
      {Icon ? (
        <Icon width={36} style={{ fill: "#383838", marginLeft: 24 }} />
      ) : null}
    </PageHeaderBase>
  );
}

const SectionHeaderBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.center ? "center" : "flex-start")};
  margin-bottom: ${(p) => (p.noMargin ? 0 : 64)}px;
  flex: 1;

  ${media.lessThan("medium")`
  margin-bottom: ${(p) => (p.noMargin ? 0 : 48)}px;
  `}
  ${media.lessThan("small")`
  margin-bottom: ${(p) => (p.noMargin ? 0 : 32)}px;
  `}
`;

export function SectionHeader({ title, center, icon, noMargin, small }) {
  const Icon = iconMap[icon];

  return (
    <SectionHeaderBase center={center} noMargin={noMargin}>
      <H2 small={small} style={{ marginBottom: 0 }}>
        {title}
      </H2>
      {Icon ? (
        <Icon
          width={small ? 28 : 32}
          style={{ fill: "#383838", marginLeft: 12 }}
        />
      ) : null}
    </SectionHeaderBase>
  );
}

export function SubSectionHeader({ title, center, icon }) {
  const Icon = iconMap[icon];

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: center ? "center" : "flex-start",
        marginBottom: 16,
        flex: 1,
      }}
    >
      <H3 style={{ marginBottom: 0 }}>{title}</H3>
      {Icon ? (
        <Icon width={24} style={{ fill: "#383838", marginLeft: 8 }} />
      ) : null}
    </div>
  );
}
